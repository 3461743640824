import './src/static/css/global.scss'
import { changeLocale } from "gatsby-plugin-intl-v6";

const fetchUserCountry = async () => {
  try {
    const response = await fetch("https://ipapi.co/json/");
    const data = await response.json();
    console.log('fetch ip country code: ', data)
    return data.country_code;
  } catch (error) {
    console.error("Error fetching user location:", error);
    return null;
  }
};

const countryToLanguageMap = {
  US: "en", // 美国 -> 英文
  GB: "en", // 英国 -> 英文
  CN: "zh", // 中国 -> 中文
  VN: "vn", // 越南
  ID: "id", // 印尼
};

const getLanguageFromCountry = (countryCode) => {
  console.log('countryToLanguage:', countryToLanguageMap[countryCode])
  return countryToLanguageMap[countryCode] || "en";
};

export const onInitialClientRender = async () => {
  localStorage.removeItem("userLanguage"); // 删除历史遗留
  const usedLanguage = localStorage.getItem("usedLanguage");
  console.log('localStorage usedLanguage: ' + usedLanguage)
  if (usedLanguage) {
    changeLocale(usedLanguage);
  } else {
    const countryCode = await fetchUserCountry();
    const language = getLanguageFromCountry(countryCode);

    changeLocale(language);
    // localStorage.setItem("usedLanguage", language); 不保存ip探测结果了
  }
};