exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-ab-tsx": () => import("./../../../src/pages/ab.tsx" /* webpackChunkName: "component---src-pages-ab-tsx" */),
  "component---src-pages-abwallet-tsx": () => import("./../../../src/pages/abwallet.tsx" /* webpackChunkName: "component---src-pages-abwallet-tsx" */),
  "component---src-pages-announcement-tsx": () => import("./../../../src/pages/announcement.tsx" /* webpackChunkName: "component---src-pages-announcement-tsx" */),
  "component---src-pages-community-tsx": () => import("./../../../src/pages/community.tsx" /* webpackChunkName: "component---src-pages-community-tsx" */),
  "component---src-pages-ecosystem-tsx": () => import("./../../../src/pages/ecosystem.tsx" /* webpackChunkName: "component---src-pages-ecosystem-tsx" */),
  "component---src-pages-faucet-tsx": () => import("./../../../src/pages/faucet.tsx" /* webpackChunkName: "component---src-pages-faucet-tsx" */),
  "component---src-pages-getab-tsx": () => import("./../../../src/pages/getab.tsx" /* webpackChunkName: "component---src-pages-getab-tsx" */),
  "component---src-pages-getnew-tsx": () => import("./../../../src/pages/getnew.tsx" /* webpackChunkName: "component---src-pages-getnew-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-mediakit-tsx": () => import("./../../../src/pages/mediakit.tsx" /* webpackChunkName: "component---src-pages-mediakit-tsx" */),
  "component---src-pages-mediareports-tsx": () => import("./../../../src/pages/mediareports.tsx" /* webpackChunkName: "component---src-pages-mediareports-tsx" */),
  "component---src-pages-press-tsx": () => import("./../../../src/pages/press.tsx" /* webpackChunkName: "component---src-pages-press-tsx" */),
  "component---src-pages-redpocket-download-tsx": () => import("./../../../src/pages/redpocket/download.tsx" /* webpackChunkName: "component---src-pages-redpocket-download-tsx" */),
  "component---src-pages-redpocket-loading-tsx": () => import("./../../../src/pages/redpocket/loading.tsx" /* webpackChunkName: "component---src-pages-redpocket-loading-tsx" */),
  "component---src-templates-announcements-tsx": () => import("./../../../src/templates/announcements.tsx" /* webpackChunkName: "component---src-templates-announcements-tsx" */),
  "component---src-templates-learn-docs-tsx": () => import("./../../../src/templates/learnDocs.tsx" /* webpackChunkName: "component---src-templates-learn-docs-tsx" */)
}

